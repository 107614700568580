<template>
<loading :active="isLoading" :is-full-page="true" :loader="'bars'" />
  <queue-card-layout :ready="queue.status == 'ready'">
    <div
      class="h-full py-2 border-2 w-36"
      :class="
        queue.status == 'ready' ? 'border-qgo-primary-bg' : 'border-green-900'
      "
    >
      <div class="flex items-center justify-center py-4 md:py-2 2xl:py-4">
        <div class="">
          <p class="font-bold">
            Q{{ queue.order_no }}
          </p>
          <p class="font-bold">
            {{ queue.customer ? queue.customer.name : '-' }}
          </p>
          <p class="">
            {{ queue.status == "skipped" ? queue.status:  queue.order_status }}
          </p>
        </div>
      </div>
      <div
        class="flex items-center justify-center w-full p-2 text-white"
        :class="queue.order_status == 'Ready' ? 'bg-qgo-primary-bg' : queue.order_status == 'Collected' || queue.order_status == 'Preparing' ? 'bg-green-900' : 'bg-green-400'"
      >
        <div class="mark-as">
          <p
            v-if="queue.order_status == 'Preparing'"
            @click="updateOrderStatus(3)"
          >
            Mark as Ready
          </p>
          <p
            v-else-if="queue.order_status == 'Ready'"
            @click="updateOrderStatus(4)"
          >
            Mark as Collected
          </p>
          <p v-else-if="queue.order_status == 'Collected'">Collected</p>
          <p v-else-if="queue.status == 'skipped'">Skipped</p>
          <p v-else>Cancelled</p>
        </div>
      </div>
    </div>
  </queue-card-layout>
</template>

<script>
import { defineComponent } from "vue";
import QueueCardLayout from "@/components/pages/QueueCardLayout.vue";
import Loading from "vue-loading-overlay";

export default defineComponent({
  props: {
    queue: {
      type: Object,
    },
  },
  emits: ['orderUpdated'],
  setup(props) {
    return { ...props };
  },
  methods: {
    updateOrderStatus(status) {
      this.isLoading = true;
      let req = {
        order_no: this.queue.order_no,
        customer_id: this.queue.customer.id,
        order_status: status,
      };
      this.$http
        .post("order/update/status", req)
        .then((response) => {
          if (response && response.status) {
            switch (status) {
              case 3: //order iss ready now
                this.queue.status = "ready";
                this.queue.order_status = "Ready";
                break;
              case 4: //order iss collected
                this.queue.status = "collected";
                this.queue.order_status = "Collected";
                let stackedOrders = sessionStorage.getItem('stackedOrders');
                if(stackedOrders) {
                  stackedOrders = JSON.parse(stackedOrders);
                  stackedOrders = stackedOrders.filter(e => e.order_no != this.queue.order_no);
                  sessionStorage.setItem("stackedOrders", JSON.stringify(stackedOrders));
                }
                break;
              case 5: //order iss ready now
                this.queue.status = "cancelled";
                this.queue.order_status = "Cancelled";
                break;
              default:
                break;
            }
          }
          this.$emit('orderUpdated');
           this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          let errormsg = "";
          if (error.response && error.response.data.message) {
            errormsg = error.response.data.message;
          } else {
            errormsg = "Something went wrong";
          }
          this.$swal.fire({
            icon: "error",
            text: errormsg,
            timer: 5000,
          });
        });
    },
  },
  components: {
    QueueCardLayout,
    Loading
  },
  watch: {
    queue() {
      this.QueueData = this.queue
    }
  },
  data() {
    return {
      isLoading :false,
      QueueData: this.queue
    }
  }
});
</script>
<style lang="scss" scoped>
.mark-as {
  cursor: pointer;
}
</style>
