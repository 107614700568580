<template>
  <div class="modal-layout">
    <h1 class="heading">Managing Your Queue</h1>
    <div>
      <p>{{ currentPopUp.text1 }}</p>
      <p>{{ currentPopUp.text2 }}</p>
      <p>{{ currentPopUp.text3 }}</p>
    </div>
    <div>
      <img src="@/assets/img/HorizBlackGrn.png" alt="" />
    </div>
    <div>
      <button
        class="
          text-white
          rounded-md
          bg-qgo-secondary-bg
          focus:outline-none
          p-2
          my-1
          mr-2
          back-button
        "
        @click="backButton()"
        :disabled="currentPopUp.index == 0"
        :class="{ 'btn-disabled': currentPopUp.index == 0 }"
      >
        Back
      </button>
      <button
        class="
          text-white
          rounded-md
          bg-qgo-secondary-bg
          focus:outline-none
          p-2
          my-1
          next-button
        "
        @click="nextButton()"
      >
        {{ currentPopUp.index + 1 === ModalText.length ? "Done" : "Next" }}
      </button>
    </div>
    <div>
      <span @click="$emit('skip-tutorial')">Skip tutorial</span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  setup() {},
  data() {
    return {
      ModalText: [
        {
          text1: "Managing your order queue is fast and simple.",
          text2: `All orders start in the "Preparing stage.\nThis is when a customer's order is being made, or they are waiting in the line."`,
          text3:
            "To move the ticket into the ready Ready for Collection stage,\ntap the ready button. If something has gone wrong,\ntap the cancel button to inform your customer",
          images: [],
        },
        {
          text1:
            "When a order is marked for collection, your customer will\nreceive a notification letting them know to collect their order.",
          text2: `Once their order has been  collected, tap the "Collected" button\nto mark the order as complete`,
          text3:
            "If there is a problem (e.g if the customer does not collect their order)\ntap the cancel button to let us know what went wrong",
          images: [],
        },
        {
          text1:
            "Completed orders will be marked according to the status you provide.",
          text2:
            "You can view your completed orders at any time by viewing your order history",
          text3: "",
          images: [],
        },
      ],
      currentPopupDetails: {
        index: 0,
        text1: "",
        text2: "",
        text3: "",
      },
    };
  },
  methods: {
    nextButton() {
      if (this.ModalText.length !== this.currentPopupDetails.index + 1) {
        this.currentPopupDetails.index = this.currentPopupDetails.index + 1;
        this.currentPopupDetails.text1 =
          this.ModalText[this.currentPopupDetails.index].text1;
        this.currentPopupDetails.text2 =
          this.ModalText[this.currentPopupDetails.index].text2;
        this.currentPopupDetails.text3 =
          this.ModalText[this.currentPopupDetails.index].text3;
      } else if (this.ModalText.length === this.currentPopupDetails.index + 1) {
        this.$emit("skip-tutorial");
      }
    },
    backButton() {
      if (this.currentPopupDetails.index > 0) {
        this.currentPopupDetails.index = this.currentPopupDetails.index - 1;
        this.currentPopupDetails.text1 =
          this.ModalText[this.currentPopupDetails.index].text1;
        this.currentPopupDetails.text2 =
          this.ModalText[this.currentPopupDetails.index].text2;
        this.currentPopupDetails.text3 =
          this.ModalText[this.currentPopupDetails.index].text3;
      }
    },
  },
  created() {
    this.currentPopupDetails = {
      text1: this.ModalText[0].text1,
      text2: this.ModalText[0].text2,
      text3: this.ModalText[0].text3,
      index: 0,
    };
  },
  computed: {
    currentPopUp() {
      return this.currentPopupDetails;
    },
  },
});
</script>
<style lang="scss" scoped>
.heading {
  font-size: 32px;
}

.modal-layout h1.heading {
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
}

.modal-layout {
  padding: 30px 40px;
}

.modal-layout p {
  color: #000;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 20px;
  white-space: pre-line;
}

.modal-layout img {
  max-width: 300px;
  margin: 0 auto 20px;
}

.modal-layout button.text-white {
  min-width: 168px;
  padding: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 6px;
}
.modal-layout span {
  color: #009a73;
  margin-top: 25px;
  display: inline-block;
  text-transform: capitalize;
  cursor: pointer;
}

.next-button {
  background-color: #009a73;
}
.back-button {
  background-color: #eaeaea;
  color: #000;
}
.back-button.btn-disabled {
  background-color: #fafafa;
  color: #c3c3c3;
}
</style>