<template>
  <transition name="slide" mode="out-in">
    <div
      v-if="expandOrder"
      id="expand-order"
      class="expand-order-mask"
      @close="expandOrder = false"
    >
      <div class="w-full h-screen p-8 x-sm:p-4">
        <div class="h-16 m-3">
          <button id="hideExpandOrder" @click="hideExpandOrder">
            <svg
              class="w-16 h-16"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
        </div>
        <div class="expand-order-card-container">
          <div
            class="flex flex-wrap x-sm:justify-center sm:justify-start md:justify-start lg:justify-start xl:justify-start"
          >
            <div
              v-for="queue in data"
              :key="queue.id"
              class="relative flex h-40 pt-1 mx-4 my-2 x-sm:mx-1"
            >
              <Card  @orderUpdated="orderUpdated()" :queue="queue"></Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Card from "@/views/Vendor/Queue/QueueCards.vue";

export default {
  props: {
    data: {
      type: Object
    }
  },
  emits:['orderStatusUpdated','closed'],
  data() {
    return {
      expandOrder: false
    };
  },
  components: {
    Card
  },
  methods: {
    orderUpdated() {
      this.$emit('orderStatusUpdated');
    },
    showExpandOrder() {
      this.expandOrder = true;
    },
    hideExpandOrder() {
      this.expandOrder = false;
      this.$emit('closed');
    }
  }
};
</script>
