<template>
  <LoadingBar v-if="isLoadingA"></LoadingBar>
  <loading :active="isLoadingA" :is-full-page="fullPage" :loader="loader" />
  <div>
    <page-header>
      <page-title title="Profile">
        <div class="flex flex-row justify-center flex-1">
          <p class="px-10 font-medium text-right x-sm:px-1">
            <button @click="toggleUploadModal" class="focus:outline-none">
              Upload New Logo
            </button>
          </p>
          <p class="px-10 font-medium text-right x-sm:px-1">
            <button
              v-if="editable == false"
              class="focus:outline-none"
              @click="enableEdit"
            >
              Edit
            </button>
            <button v-else class="focus:outline-none" @click="saveDetails">
              Save
            </button>
          </p>
        </div>
      </page-title>
    </page-header>

    <div class="vendor-container">
      <div class="vendor-content">
        <div
          class="
            inline-block
            min-w-full
            overflow-hidden
            align-middle
            border-b border-gray-200
            sm:rounded-lg
          "
        ></div>
        <form>
          <div>
            <h1 class="text-left">BUSINESS DETAILS</h1>
          </div>
          <div
            class="
              px-3
              py-3
              bg-white
              border-b border-gray-200
              mt-
              sm:rounded-lg
            "
          >
            <label class="flex flex-row items-center mt-1">
              <span class="w-1/12 text-left text-gray-700">Business Name:</span>
              <input
                name="businessName"
                type="text"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="Business Name"
                :disabled="editable == false"
                v-model="input.bussiness_name"
                @input="busNameVerification"
                @blur="onBlur('bussiness_name')"
              />
            </label>
            <div
              class="flex flex-row items-center mt-1"
              v-if="!validStatus.bussiness_name"
            >
              <span class="w-1/12 text-left text-gray-700"></span>
              <p class="pl-5 text-xs italic text-left text-red-500 errorMsg">
                {{ errors.bussiness_name }}
              </p>
            </div>

            <label class="flex items-center flex-auto mt-1">
              <span class="w-1/12 text-left text-gray-700">T/as:</span>
              <input
                name="tradingAs"
                type="text"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="Trading As"
                :disabled="editable == false"
                @input="tradingAsVerification"
                v-model="input.trading_as"
                @blur="onBlur('trading_as')"
              />
            </label>
            <div
              class="flex flex-row items-center mt-1"
              v-if="!validStatus.trading_as"
            >
              <span class="w-1/12 text-left text-gray-700"></span>
              <p class="pl-5 text-xs italic text-left text-red-500 errorMsg">
                {{ errors.trading_as }}
              </p>
            </div>
            <label class="flex items-center flex-auto mt-1">
              <span class="w-1/12 text-left text-gray-700">ABN:</span>
              <input
                name="abn"
                type="text"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="00 000 000 000"
                :disabled="editable == false"
                @input="abnVerification"
                @blur="onBlur('abn')"
                v-model="input.abn"
              />
            </label>
            <div
              class="flex flex-row items-center mt-1"
              v-if="!validStatus.abn"
            >
              <span class="w-1/12 text-left text-gray-700"></span>
              <p class="pl-5 text-xs italic text-left text-red-500 errorMsg">
                {{ errors.abn }}
              </p>
            </div>
            <label class="flex items-center flex-auto mt-1">
              <span class="w-1/12 text-left text-gray-700">Address:</span>
              <input
                name="address"
                type="text"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="Address"
                :disabled="editable == false"
                v-model="input.address"
                @input="addressVerification"
                @blur="onBlur('address')"
              />
            </label>
            <div
              class="flex flex-row items-center mt-1"
              v-if="!validStatus.address"
            >
              <span class="w-1/12 text-left text-gray-700"></span>
              <p class="pl-5 text-xs italic text-left text-red-500 errorMsg">
                {{ errors.address }}
              </p>
            </div>
            <label class="flex items-center flex-auto mt-1">
              <span class="w-1/12 text-left text-gray-700">Suburb:</span>
              <input
                name="suburb"
                type="text"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="Suburb"
                :disabled="editable == false"
                v-model="input.suburb"
                @input="suburbVerification"
                @blur="onBlur('suburb')"
              />
            </label>
            <div
              class="flex flex-row items-center mt-1"
              v-if="!validStatus.suburb"
            >
              <span class="w-1/12 text-left text-gray-700"></span>
              <p class="pl-5 text-xs italic text-left text-red-500 errorMsg">
                {{ errors.suburb }}
              </p>
            </div>
            <label class="flex items-center flex-auto mt-1">
              <span class="w-1/12 text-left text-gray-700">Postcode:</span>
              <input
                name="postCode"
                type="text"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="0000"
                :disabled="editable == false"
                v-model="input.postcode"
                @input="postcodeVerification"
                @blur="onBlur('postcode')"
              />
            </label>
            <div
              class="flex flex-row items-center mt-1"
              v-if="!validStatus.postcode"
            >
              <span class="w-1/12 text-left text-gray-700"></span>
              <p class="pl-5 text-xs italic text-left text-red-500 errorMsg">
                {{ errors.postcode }}
              </p>
            </div>
            <label class="flex items-center flex-auto mt-1">
              <span class="w-1/12 text-left text-gray-700">State:</span>
              <select :disabled="editable == false" @change="stateVerification" v-model="input.state"  name="" id="" class="flex-1 block w-full mt-1 ml-2 border-transparent rounded">
                <option value="">Select state</option>
                <option v-for="state in statesArray" v-bind:key="state.id" :value="state.id">{{ state.name }}</option>
                <!-- <option value="ACT">ACT</option>
                <option value="NSW">NSW</option>
                <option value="NT">NT</option>
                <option value="QLD">QLD</option>
                <option value="SA">SA</option>
                <option value="WA">WA</option>
                <option value="TAS">TAS</option>
                <option value="VIC">VIC</option> -->
              </select>
              <!-- <input
                name="state"
                type="text"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="State"
                :disabled="editable == false"
                v-model="input.state"
                @input="stateVerification"
                @blur="onBlur('state')"
              /> -->
            </label>
            <div
              class="flex flex-row items-center mt-1"
              v-if="!validStatus.state"
            >
              <span class="w-1/12 text-left text-gray-700"></span>
              <p class="pl-5 text-xs italic text-left text-red-500 errorMsg">
                {{ errors.state }}
              </p>
            </div>
            <label class="flex items-center flex-auto mt-1">
              <span class="w-1/12 text-left text-gray-700">Country:</span>
              <input
                name="country"
                type="text"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="Country"
                disabled="true"
                :value="input.country"
              />
            </label>
            <div
              class="flex flex-row items-center mt-1"
              v-if="!validStatus.country"
            >
              <span class="w-1/12 text-left text-gray-700"></span>
              <p class="pl-5 text-xs italic text-left text-red-500 errorMsg">
                {{ errors.country }}
              </p>
            </div>
          </div>

          <div
            class="
              px-3
              py-3
              bg-white
              border-b border-gray-200
              mt-
              sm:rounded-lg
            "
          >
            <label class="flex flex-row items-center mt-1">
              <span class="text-left text-gray-700 md:w-1/12"
                >Contact Name:</span
              >
              <input
                name="contactName"
                type="text"
                v-model="input.contact_name"
                @input="contactNameVerification"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                :disabled="editable == false"
                placeholder="Contact name"
                @blur="onBlur('contact_name')"
              />
            </label>
            <div
              class="flex flex-row items-center mt-1"
              v-if="!validStatus.contact_name"
            >
              <span class="w-1/12 text-left text-gray-700"></span>
              <p class="pl-5 text-xs italic text-left text-red-500 errorMsg">
                {{ errors.contact_name }}
              </p>
            </div>
            <label class="flex items-center flex-auto mt-1">
              <span class="w-1/12 text-left text-gray-700">Phone:</span>
              <input
                name="phone"
                type="tel"
                pattern="^[0-9]{10}$"
                minlength="10"
                maxlength="10"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="0491123456"
                :disabled="true"
                v-model="input.phone"
                @input="setProfileData('phoneNumber', $event)"
              />
            </label>
            <label class="flex items-center flex-auto mt-1">
              <span class="w-1/12 text-left text-gray-700">Email:</span>
              <input
                name="email"
                type="text"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="email@example.com"
                :disabled="true"
                v-model="input.email"
                @input="setProfileData('email', $event)"
              />
            </label>
          </div>
        </form>

        <!-- modal for upload -->
        <div v-if="uploadModal" @keydown.esc="closeUploadModal">
          <modal-view>
            <div class="flex flex-col content-center">
              <div
                class="flex items-center justify-center w-full bg-grey-lighter"
              >
                <label
                  class="
                    flex flex-col
                    items-center
                    w-screen
                    px-4
                    py-6
                    tracking-wide
                    uppercase
                    bg-white
                    border
                    rounded-lg
                    shadow-lg
                    cursor-pointer
                    text-blue
                    border-blue
                    hover:bg-qgo-primary-bg hover:text-white
                  "
                >
                  <svg
                    class="w-8 h-8"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
                    />
                  </svg>
                  <p v-if="selectedFile == null">Select A file</p>
                  <p v-if="selectedFile != null">
                    {{ selectedFile[0].name }}
                  </p>
                  <p class="text-xs">Min Dimension: 200x200</p>
                  <p class="text-xs">Max Dimension: 500x500</p>
                  <input
                    type="file"
                    class="hidden bg focus:outline-none"
                    @change="FileSelected"
                    accept=".png, .jpg, .jpeg, .svg"
                  />
                </label>
              </div>
              <div class="px-3 pb-1">
                <button
                  v-if="selectedFile != null"
                  @click="proceedUpload"
                  class="
                    w-full
                    h-full
                    py-3
                    pt-3
                    text-white
                    rounded-md
                    text-md
                    bg-qgo-primary-bg
                    focus:outline-none
                  "
                >
                  Upload
                </button>
              </div>
              <div class="px-3 pb-3">
                <button
                  @click="cancelUpload"
                  class="
                    w-full
                    h-full
                    py-3
                    pt-3
                    text-white
                    bg-red-900
                    rounded-md
                    text-md
                    focus:outline-none
                  "
                >
                  Cancel
                </button>
              </div>
            </div>
          </modal-view>
        </div>
      </div>
    </div>
  </div>

  <page-footer>
    <footer-nav></footer-nav>
  </page-footer>
</template>

<script>
import { defineComponent } from "vue";
import PageHeader from "@/components/pages/Header.vue";
import PageTitle from "@/views/Vendor/PageTitle.vue";
import PageFooter from "@/components/pages/Footer.vue";
import FooterNav from "@/views/Vendor/FooterNav.vue";
import LoadingBar from "@/components/loader/Loader.vue";
import ModalView from "@/components/Modal.vue";
import Loading from "vue-loading-overlay";

export default defineComponent({
  setup() {
    return {};
  },
  components: {
    PageHeader,
    PageTitle,
    PageFooter,
    FooterNav,
    LoadingBar,
    ModalView,
    Loading,
  },
  data() {
    return {
      statesArray: [],
      userId: undefined,
      isLoadingA: true,
      fullPage: true,
      loader: "bars",
      input: {
        bussiness_name: "",
        trading_as: "",
        abn: "",
        address: "",
        suburb: "",
        postcode: "",
        state: "",
        country: "",
        contact_name: "",
        phone: "",
        email: "",
      },
      blurStatus: {
        bussiness_name: false,
        trading_as: false,
        abn: false,
        address: false,
        suburb: false,
        postcode: false,
        state: true,
        country: false,
        contact_name: false,
      },
      errors: {
        bussiness_name: "",
        trading_as: "",
        abn: "",
        address: "",
        suburb: "",
        postcode: "",
        state: "",
        country: "",
        contact_name: "",
      },
      validStatus: {
        bussiness_name: true,
        trading_as: true,
        abn: true,
        address: true,
        suburb: true,
        postcode: true,
        state: true,
        country: true,
        contact_name: true,
      },
    };
  },
  methods: {
    busNameVerification() {
      if (this.blurStatus.bussiness_name) {
        if (this.input.bussiness_name) {
          if (
            this.input.bussiness_name.length < 2 ||
            this.input.bussiness_name.length > 100
          ) {
            this.errors.bussiness_name =
              "Business name should be greater than 2 and less than 100 characters";
            this.validStatus.bussiness_name = false;
          } else {
            this.errors.bussiness_name = "";
            this.validStatus.bussiness_name = true;
          }
        } else {
          this.errors.bussiness_name = "Bussiness name is required";
          this.validStatus.bussiness_name = false;
        }
      } else {
        this.errors.bussiness_name = "";
        this.validStatus.bussiness_name = true;
      }
    },
    stateVerification() {
      if (this.blurStatus.state) {
        if (this.input.state) {
          if (this.input.state.length > 50) {
            this.errors.state = "State should not be greater than 50";
            this.validStatus.state = false;
          } else {
            this.errors.state = "";
            this.validStatus.state = true;
          }
        } else {
          this.errors.state = "State is required";
          this.validStatus.state = false;
        }
      }
    },
    countryVerification() {
      if (this.blurStatus.country) {
        if (this.input.country) {
          if (this.input.country.length > 50) {
            this.errors.country = "Country should not be greater than 50";
            this.validStatus.country = false;
          } else {
            this.errors.country = "";
            this.validStatus.country = true;
          }
        } else {
          this.errors.country = "Country is required";
          this.validStatus.country = false;
        }
      }
    },
    validateABN(abn) {
      var isValid = true;
      abn = abn.toString();
      //remove all spaces
      abn = abn.replace(/\s/g, "");
      //0. ABN must be 11 digits long
      isValid &= abn && /^\d{11}$/.test(abn);
      if (isValid) {
        var weightedSum = 0;
        var weight = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
        //Rules: 1,2,3
        for (var i = 0; i < weight.length; i++) {
          weightedSum += (parseInt(abn[i]) - (i === 0 ? 1 : 0)) * weight[i];
        }
        //Rules: 4,5
        isValid &= weightedSum % 89 === 0;
      }
      return isValid;
    },
    abnVerification() {
      if (this.blurStatus.abn) {
        if (this.input.abn) {
          let valid = this.validateABN(this.input.abn);
          if (valid) {
            this.errors.abn = "";
            this.validStatus.abn = true;
          } else {
            this.errors.abn =
              "Invalid ABN (only numbers with maximum length 11)";
            this.validStatus.abn = false;
          }
        } else {
          this.errors.abn = "ABN is required";
          this.validStatus.abn = false;
        }
      } else {
        this.errors.abn = "";
        this.validStatus.abn = true;
      }
    },
    contactNameVerification() {
      if (this.blurStatus.contact_name) {
        if (this.input.contact_name) {
          this.errors.contact_name = "";
          this.validStatus.contact_name = true;
        } else {
          this.errors.contact_name = "Contact name is required";
          this.validStatus.contact_name = false;
        }
      } else {
        this.errors.contact_name = "";
        this.validStatus.contact_name = true;
      }
    },
    postcodeVerification() {
      if (this.blurStatus.postcode) {
        if (this.input.postcode) {
          // const regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
          const regex = /^\d+$/;
          if (regex.test(this.input.postcode)) {
            this.errors.postcode = "";
            this.validStatus.postcode = true;
          } else {
            this.errors.postcode = "Invalid post code";
            this.validStatus.postcode = false;
          }
        } else {
          this.errors.postcode = "";
          this.validStatus.postcode = true;
        }
      }
    },
    addressVerification() {
      if (this.blurStatus.address) {
        if (this.input.address) {
          if (this.input.address.length > 255) {
            this.errors.address =
              "Address should not be greater than 255 characters";
            this.validStatus.address = false;
          } else {
            this.errors.address = "";
            this.validStatus.address = true;
          }
        } else {
          this.errors.address = "";
          this.validStatus.address = true;
        }
      }
    },
    suburbVerification() {
      if (this.blurStatus.suburb) {
        if (this.input.suburb) {
          if (this.input.suburb.length > 255) {
            this.errors.suburb =
              "Suburb should not be greater than 255 characters";
            this.validStatus.suburb = false;
          } else {
            this.errors.suburb = "";
            this.validStatus.suburb = true;
          }
        } else {
          this.errors.suburb = "";
          this.validStatus.suburb = true;
        }
      }
    },
    tradingAsVerification() {
      if (this.blurStatus.trading_as) {
        if (this.input.trading_as) {
          if (this.input.trading_as.length > 255) {
            this.errors.trading_as =
              "Trading_as should not be greater than 255 characters";
            this.validStatus.trading_as = false;
          } else {
            this.errors.trading_as = "";
            this.validStatus.trading_as = true;
          }
        } else {
          this.errors.trading_as = "";
          this.validStatus.trading_as = true;
        }
      }
    },
    onBlur(field) {
      switch (field) {
        case "trading_as":
          this.blurStatus.trading_as = true;
          this.tradingAsVerification();
          break;
        case "suburb":
          this.blurStatus.suburb = true;
          this.suburbVerification();
          break;
        case "address":
          this.blurStatus.address = true;
          this.addressVerification();
          break;
        case "bussiness_name":
          this.blurStatus.bussiness_name = true;
          this.busNameVerification();
          break;
        case "contact_name":
          this.blurStatus.contact_name = true;
          this.contactNameVerification();
          break;
        case "abn":
          this.blurStatus.abn = true;
          this.abnVerification();
          break;
        case "state":
          this.blurStatus.state = true;
          this.stateVerification();
          break;
        case "country":
          this.blurStatus.country = true;
          this.countryVerification();
          break;
        case "postcode":
          this.blurStatus.postcode = true;
          this.postcodeVerification();
          break;
        default:
          break;
      }
    },
    enableEdit() {
      this.$store.commit("profile/setEditable", true);
    },
    proceedUpload(event) {
      this.isLoadingA = true;
      console.log(event);
      this.$store.commit("profile/setLoading", true);
      // upload here
      const formData = new FormData();
      for (const i of Object.keys(this.selectedFile)) {
        formData.append("logo", this.selectedFile[i]);
      }
      console.log(formData);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      this.$http.post("address/logo-upload", formData, config).then(
        (response) => {
          this.isLoadingA = false;
          this.$store.commit("profile/setUploadModal", false);
          this.$store.commit("profile/setLoading", false);
          console.log(response);
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: response.data.message,
          });
          this.$store.commit("profile/setSelectedFile", null);
          this.$store.commit("profile/setVendorLogo", response.data.url);
          let user = JSON.parse(localStorage.getItem("userData"));
          if (user && user.email) {
            user.logo = response.data.url;
            localStorage.setItem("userData", JSON.stringify(user));
          }
        },
        (error) => {
          this.$store.commit("profile/setUploadModal", false);
          this.$store.commit("profile/setLoading", false);
          this.isLoadingA = false;
          console.log(error);
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "error",
            title: "Logo Upload Failed",
          });
        }
      );
    },
    cancelUpload() {
      this.$store.commit("profile/setUploadModal", false);
      this.$store.commit("profile/setSelectedFile", null);
    },
    toggleUploadModal() {
      this.$store.commit("profile/setUploadModal", !this.uploadModal);
    },
    FileSelected(e) {
      let ref = this;
      let fileName = e.target.files[0].name;
      let idxDot = fileName.lastIndexOf(".") + 1;
      let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (
        extFile == "jpg" ||
        extFile == "jpeg" ||
        extFile == "png" ||
        extFile == "svg"
      ) {
        let img = new Image();
        let objectUrl = window.URL.createObjectURL(e.target.files[0]);
        img.onload = function () {
          if (
            this.width > 500 ||
            this.width < 200 ||
            this.height < 200 ||
            this.height > 500
          ) {
            console.log("Invalid file");
            ref.$store.commit("profile/setSelectedFile", null);
            ref.$swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              icon: "error",
              title:
                "Please select a file with MIN DIMENSION: 200X200 and MAX DIMENSION: 500X500",
            });
          } else {
            console.log("Valid file");
            ref.$store.commit("profile/setSelectedFile", e.target.files);
            console.log("1", this.selectedFile);
          }
        };
        img.src = objectUrl;
        //TO DO
      } else {
        this.$store.commit("profile/setSelectedFile", null);
        this.$swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          icon: "error",
          title: "Please select a valid image(jpg, jpeg, png, svg)",
        });
        // alert("Only jpg/jpeg and png files are allowed!");
      }
    },
    saveDetails() {
      let arr = [
        "trading_as",
        "suburb",
        "address",
        "bussiness_name",
        "contact_name",
        "abn",
        "state",
        "country",
        "postcode",
      ];
      for (let index = 0; index < arr.length; index++) {
        this.onBlur(arr[index]);
      }
      if (
        this.validStatus.trading_as &&
        this.validStatus.suburb &&
        this.validStatus.address &&
        this.validStatus.bussiness_name &&
        this.validStatus.contact_name &&
        this.validStatus.abn &&
        this.validStatus.state &&
        // this.validStatus.country &&
        this.validStatus.postcode
      ) {
        this.isLoadingA = true;
        let reqData = {
          business_name: this.input.bussiness_name,
          trading_as: this.input.trading_as,
          abn: this.input.abn.replace(/\s+/g, ""),
          address: this.input.address,
          suburb: this.input.suburb,
          postcode: this.input.postcode ? parseInt(this.input.postcode) : null,
          state: this.input.state,
          // country: this.input.country,
          name: this.input.contact_name,
        };
        if (!reqData.postcode) delete reqData.postcode;

        this.$http
          .post("address/update", reqData)
          .then((response) => {
            this.isLoadingA = false;
            let user = JSON.parse(localStorage.getItem("userData"));
            if (user && user.name) {
              user.name = this.input.contact_name;
              localStorage.setItem("userData", JSON.stringify(user));
            }
            this.$store.commit("profile/setEditable", false);
            this.$store.commit(
              "profile/setvendorName",
              this.input.contact_name
            );

            this.$swal.fire({
              icon: "success",
              title: response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response.data);
            let errorMessage = "";
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              if (
                Array.isArray(error.response.data.message) &&
                error.response.data.message.length
              ) {
                errorMessage = error.response.data.message[0];
              } else if (typeof error.response.data.message === "string") {
                errorMessage = error.response.data.message;
              } else {
                errorMessage = "Something went wrong.";
              }
            } else {
              errorMessage = "Something went wrong.";
            }
            this.isLoadingA = false;
            this.$swal.fire({
              icon: "error",
              title: errorMessage,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          });
        console.log(reqData);
      } else {
        console.log("invalid");
      }
      // this.$store.commit("profile/setEditable", false);
      // this.$store.dispatch("profile/updateProfileData").then(
      //   (response) => {
      //     console.log(response);
      //     this.$swal.fire({
      //       toast: true,
      //       position: "top-end",
      //       showConfirmButton: false,
      //       timer: 4000,
      //       icon: "success",
      //       title: "Updated Successfully",
      //     });
      //   },
      //   (error) => {
      //     console.log(error);
      //     this.$swal.fire({
      //       toast: true,
      //       position: "top-end",
      //       showConfirmButton: false,
      //       timer: 4000,
      //       icon: "error",
      //       title: "Update Failed Update FailedUpdate FailedUpdate Failed",
      //     });
      //   }
      // );
    },
    setProfileData(val, e) {
      let payload = {
        name: val,
        value: e.target.value,
      };
      this.$store.commit("profile/setSingleInputData", payload);
    },
  },
  computed: {
    profileData() {
      return this.$store.getters["profile/inputData"];
    },
    uploadModal() {
      return this.$store.getters["profile/uploadModal"];
    },
    selectedFile() {
      return this.$store.getters["profile/selectedFile"];
    },
    editable() {
      return this.$store.getters["profile/editable"];
    },
    isLoading() {
      return this.$store.getters["profile/isLoading"];
    },
  },
  created() {
    let user = JSON.parse(localStorage.getItem("userData"));
    this.userId = user.id;
    this.$http
      .get("auth/user-profile")
      .then((response) => {
        let data = response.data.data;
        this.input.bussiness_name = data.business_name;
        this.input.trading_as = data.trading_as;
        this.input.abn = data.abn;
        this.input.address = data.address ? data.address.address : "";
        this.input.suburb = data.address ? data.address.suburb : "";
        this.input.postcode = data.address ? data.address.postcode : "";
        this.input.state = data.address ? data.address.state ? data.address.state.id ? data.address.state.id : "" : "" : "";
        this.input.country = data.address ? data.address.country : "";
        this.input.contact_name = data.user.name;
        this.input.phone = data.user.phone_number;
        this.input.email = data.user.email;
        this.isLoadingA = false;
        this.statesArray = response.data.states
      })
      .catch((error) => {
        let errormsg = "";
        if (error.response && error.response.data) {
          errormsg = error.response.data.message;
        } else {
          errormsg = error.message;
        }
        this.isLoadingA = false;
        this.$swal.fire({
          icon: "error",
          title: errormsg,
          timer: 5000,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
        });
      });
    // this.$store.dispatch("profile/fetchProfileInitialData").then(
    //   response => {
    //     console.log(response);
    //   },
    //   error => {
    //     console.log(error);
    //     this.$swal.fire({
    //       icon: "error",
    //       text: "Something went wrong while fetching Profile!",
    //       timer: 5000
    //     });
    //   }
    // );
  },
});
</script>
<style scoped>
input::placeholder {
  color: #2c3e5047;
}
</style>
